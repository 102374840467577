
    import './App.css';
    import { Container } from 'react-bootstrap';
    
    function App() {
    
      return (
        <Container id = "app">
          <h1 className = "title-text">send me ether!</h1>
          <h3 className = "wallet-address-title">wallet address:</h3>
          <h3 className = "wallet-address">0x1c06EC84aa3f8c48E98D7498AAa15F391857304A</h3>
          <h3 className = "thanks-text">thanks, I appreciate it.</h3>
        </Container>
        
      );
    }

export default App;
